import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import About from "./Components/About";
import Logos from "./Components/Logos";
import Price from "./Components/Price";
import Place from "./Components/Place";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Alert from "./Components/Alert"
import ScrollToTop from "react-scroll-to-top";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const App = () => {

    const showAlert = true;
    const alertMsg = (<span>ADLANDEK - z důvodu výpadku proudu <b>pondělí 16.9. neplaveme</b>. <b>Čapkarna do odvolání z důvodu zatopení neplaveme</b>. Lekce nepropadají</span>)

  return (
      <div>
          { showAlert ? <Alert type="Info" msg={alertMsg}/> : null }
          <Navbar/>
          <Hero/>
          <Logos/>
          <About/>
          <Price/>
          <Place/>
          <Contact/>
          <Footer/>
          <ScrollToTop className="dark:bg-gray-900" smooth component={<p className="text-fuchsia-600 dark:text-white"><KeyboardDoubleArrowUpIcon/></p>}/>
      </div>

  )
}


export default App